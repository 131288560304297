import React, { useEffect, useContext } from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Title, Section, Text} from "../../components/Core";
import CheckAvailability from "../index/CheckAvailability";
import NetomniaRegions from "../index/NetomniaRegions";
import GlobalContext from "../../context/GlobalContext";

const BroadbandNetwork = (props) => {
    const gContext = useContext(GlobalContext)
    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])

    return (
        <>
            <Section className="position-relative pt-2 broadband-network-wrapper">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="11" className="text-center pb-3">
                            <div className="pt-5 mt-5">
                                <Title>{props.headingsData.h22}</Title>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center justify-content-center pb-5 mb-3 mt-0">
                        <Col sm={12} md={12} className="text-center mx-auto pt-4">
                            <Text dangerouslySetInnerHTML={{ __html: props.data.network_subtitle.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Text>
                            <Text
                                as="h4"
                                color="heading"
                                fontSize="1.7em"
                                fontWeight={600}
                                letterSpacing={-0.75}
                                mb={0}
                                className="benefit-subtitle m-5"
                            >
                                {props.data.network_subtitle_1}
                            </Text>
                            <NetomniaRegions mapOnly={true}/>
                            <Text
                                as="h4"
                                color="heading"
                                fontSize="1.7em"
                                fontWeight={600}
                                letterSpacing={-0.75}
                                mb={0}
                                className="benefit-subtitle m-5"
                            >
                                {props.headingsData.h3}
                            </Text>
                            <Text>
                                {props.data.check_availability_subtitle}
                            </Text>
                        </Col>
                    </Row>
                    <CheckAvailability 
                        isFocused={false}
                        hideLogo={true}
                        customTitle={""}
                    />
                </Container>
            </Section>
        </>
    )
}

export default BroadbandNetwork;
