import React from "react";
import PageWrapper from "../components/PageWrapper";
import "../assets/map/LayeredUKMap-v2.scss"
import Hero from "../sections/broadband/Hero";
import Benefits from "../sections/broadband/Benefits";
import BroadbandNetwork from "../sections/broadband/BroadbandNetwork";
import Seo from "./../components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../utils/helperFn";

const Broadband = () => {

    const data = useStaticQuery(graphql`
        query BroadbandQuery {
            strapiBroadbandPage {
                hero_title
                hero_subtitle
                benefits_title
                benefits_subtitle
                benefit_1_title
                benefit_1_subtitle
                benefit_1_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                benefit_2_title
                benefit_2_subtitle
                benefit_2_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                benefit_3_title
                benefit_3_subtitle
                benefit_3_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                network_title
                network_subtitle
                network_subtitle_1
                check_availability_title
                check_availability_subtitle
            }
            strapiPageHeadings(slug: {eq: "broadband"}) {
                h1
                h21
                h22
                h3
            }
        }`
    );

    const pageData = getProperty(data, 'strapiBroadbandPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    return (
        <>
            <Seo page="broadband"/>
            <PageWrapper>
                <Hero data={pageData} headingsData={headingsData}/>
                <Benefits data={pageData} headingsData={headingsData}/>
                <BroadbandNetwork data={pageData} headingsData={headingsData}/>
            </PageWrapper>
        </>
    );
};
export default Broadband;
