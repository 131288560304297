import React, {createRef, useEffect} from "react";
import {Container, Row, Col } from "react-bootstrap";
import {
    Section,
    Box,
    Text, MainTitle
} from "../../components/Core";
import animation from "../../assets/animations/broadband-main.json"
import netomniaHero from  "../../assets/image/svg/broadband-hero-image.svg";
import lottie from "lottie-web"

const Hero = (props) => {

    const animationContainer = createRef()

    
    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    return (
        <>
            <Section bg="#ffffff" className="position-relative pt-5 mt-5">
                <div className="d-none d-md-block" style={{paddingTop: "110px"}}/>
                <Container>
                    <Row className="align-items-center position-relative">

                        <Col md="7" xl="4" className="order-1 order-md-0 mt-5 mt-md-0">
                            <div
                                className="pt-0 pt-md-4"
                                data-aos="fade-left"
                                data-aos-duration="750"
                                data-aos-delay="500"
                                data-aos-once="true"
                            >
                                <Box>
                                    <MainTitle className="text-left head-title" style={{ fontSize: '60px' }} dangerouslySetInnerHTML={{ __html: props.headingsData.h1 }}></MainTitle>
                                    <Box mb={4} className="text-left">
                                        <Text color="dark">{props.data.hero_subtitle}</Text>
                                    </Box>
                                </Box>
                            </div>
                        </Col>

                        <Col
                            md="5"
                            xl="8"
                            className="order-0 order-md-1"
                            data-aos="fade-right"
                            data-aos-duration="750"
                            data-aos-delay="500"
                            data-aos-once="true"
                        >
                            {/*<img alt="" src={netomniaHero} className="img-fluid"/>*/}
                            <div id="animation-container" ref={animationContainer}/>
                        </Col>

                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Hero;
